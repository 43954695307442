<template>
  <v-card flat :style="`height: ${ windowSize.y + 30 }px; position: 'relative'`" class="pa-1 product-view" v-resize="onResizeHandler">
    <div class="text-center" v-if="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card-text class="pa-0">
      <v-form ref="pickForm" v-on:submit.prevent>
        <v-row>
          <v-col cols="6" class="pb-0 pt-0 font-weight-bold">{{ $t('message.layout.orderno') }}: {{ orderModel.number }}</v-col>
          <v-col cols="6" class="pb-0 pt-0 text-right">{{ $t('message.order.delivery') }}: {{ orderModel.deliverydate | dateFormat }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0 pl-3 font-weight-bold">{{ $t('message.projects.customer') }}: {{ orderModel.customer_name || '' }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-0 pr-0 pb-0 mt-1">
            <barcode-scanner v-model="scanCode" :search="scanCode" :scannerPayload="scannerPayload"
            :loading="productLoading"></barcode-scanner> <!--  :disabled="isAllPicked" -->
            <!-- <v-text-field outlined dense hide-details v-model="scanCode" flat :disabled="isAllPicked" autocomplete="off"
              ref="scanFocus" @change="getRespectiveProductOrder" :loading="productLoading"
            :placeholder="$t('message.order.scanProduct')"></v-text-field> -->
          </v-col>
          <v-col cols="12" class="pt-1 pb-1 font-weight-bold" v-if="productFound">
            {{ $t('message.equipments.product') }}: {{ productInfo.description || '' }}
          </v-col>
          <!-- <v-col cols="12" class="pa-0 pr-1 my-1" v-if="productFound">
            <v-autocomplete :items="listOfWarehouses" v-model="selectedWarehouse" item-text="shortname" item-value="id"
            hide-details outlined :label="$t('message.layout.warehouse')" dense :rules="$_requiredValidation"
            @change="getLocationsByWarehouse"></v-autocomplete>
          </v-col> -->
          <template v-if="productFound && productInfo.isbatch"><!--  && (productInfo.producttype === 'product') -->
            <v-col cols="7" class="pt-0 pb-0">
              <!-- <v-text-field outlined dense hide-details v-model="selectBatch" flat @change="checkTheBatchnumber" autocomplete="off"
              :rules="$_requiredValidation" :placeholder="$t('message.product.batch')" ref="batchRef"
              :loading="loadingBatch"></v-text-field> -->
              <v-autocomplete :items="getbatch" v-model="selectBatch" item-text="batchnumberpwa" item-value="batchnumberpwa"
                hide-details outlined :loading="loadingBatch" :label="$t('message.product.batch')" flat autocomplete="off"
                dense :rules="$_requiredValidation" ref="batchRef" @change="checkTheBatchnumber"></v-autocomplete>
            </v-col>
            <v-col cols="5" class="pl-0 pt-0 pb-0" v-if="moreLocationsFound">
              <!-- <v-text-field outlined dense hide-details v-model="selectedLocation" flat autocomplete="off"
                :rules="$_requiredValidation" @change="checkIfLocationValid" :loading="loadingLocation"
                ref="moreLocation" :placeholder="$t('message.product.scanLoc')"></v-text-field> -->
                <v-autocomplete :items="batchLocations" v-model="selectedLocation" :item-text="getItemText" item-value="locationname"
                hide-details outlined :loading="loadingLocation" :label="$t('message.product.scanLoc')" flat autocomplete="off"
                dense :rules="$_requiredValidation" ref="moreLocation" @change="checkIfLocationValid"></v-autocomplete>
            </v-col>
          </template>
          <v-col cols="8" class="pt-0 pb-0" v-if="productFound && !productInfo.isbatch"><!--  && (productInfo.producttype === 'product') -->
            <!-- <v-text-field outlined dense hide-details v-model="selectedLocation" flat autocomplete="off"
              :rules="$_requiredValidation" @change="checkIfLocationValid" :loading="loadingLocation"
              ref="moreLocation" :placeholder="$t('message.product.scanLoc')"></v-text-field> -->
              <v-autocomplete :items="listOfLocations" v-model="selectedLocation" :item-text="getItemText" item-value="locationname"
                hide-details outlined :loading="loadingLocation" :label="$t('message.product.scanLoc')" flat autocomplete="off"
                dense :rules="$_requiredValidation" ref="moreLocation" @change="checkIfLocationValid"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="productFound">
          <v-col cols="9" class="pa-1">
            <v-row>
              <v-col cols="3" class="text-center pa-0" @click="decreaseQty">
                <v-btn color="info" fab x-small>
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" class="pa-0">
                <v-text-field outlined dense hide-details type="number" class="text-right" v-model="pickQty"
                  autocomplete="off" ref="qtyRef" :rules="$_qtyValidation" :placeholder="$t('message.product.qty')"></v-text-field>
              </v-col>
              <v-col cols="3" class="text-center pa-0" @click="increaseQty">
                <v-btn color="info" fab x-small>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3" class="pa-1 mt-1">
            <v-btn color="primary" dark small @click="pickQtyOnOrder" :loading="saveLoading">
              {{ $t('message.order.pick') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <!-- TO BE PICKED PRODUCTS -->
    <v-card-text class="highlight pt-0 pb-0 pl-1 mt-2">
      <v-row>
        <v-col cols="4" class="pa-0 pl-3 font-weight-bold">{{ $t('message.order.toPick') }}:</v-col>
        <v-col cols="3" class="pa-0 font-weight-bold">{{ $t('message.order.location') }}</v-col>
        <v-col cols="2" class="pa-0 pl-3 text-right font-weight-bold">{{ $t('message.order.avail') }}</v-col>
        <v-col cols="3" class="pa-0 pl-3 text-right font-weight-bold">{{ $t('message.order.ordered') }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-0">
          <v-divider class="pa-0"></v-divider>
        </v-col>
      </v-row>
      <!-- Load list of products -->
      <v-row v-for="(product, index) in listOfProducts.topickproducts" :key="index">
        <v-col cols="4" class="pa-0 pl-3 text-truncate" :title="product.number" :class="{ 'pb-1': (index === listOfProducts.topickproducts.length - 1)}">
          {{ product.number }}
        </v-col>
        <v-col cols="5" class="pa-0">
          <v-row v-for="(location, index) in product.locations" :key="`${index}_location`">
            <v-col cols="6" class="pa-0 text-truncate" :title="location.locationname">{{ location.locationname }}</v-col>
            <v-col cols="6" class="pa-0 pl-3 text-right text-truncate" :title="location.availableqty">
              {{ (location.availableqty) | absoluteNumber }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" class="pa-0 pl-3 text-right">
          {{ product.orderedqty | absoluteNumber }}
          <span class="caption text-truncate" :title="product.unitname">{{ product.unitname }}</span>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- PICKED PRODUCTS -->
    <v-card-text tabindex="0" class="highlight pt-0 pb-0 pl-1 mt-2">
      <v-row>
        <v-col cols="3" class="pa-0 pl-3 font-weight-bold">{{ $t('message.order.picked') }}:</v-col>
        <v-col cols="3" class="pa-0 pr-2 text-center font-weight-bold">{{ $t('message.order.location') }}</v-col>
        <v-col cols="2" class="pa-0 text-right font-weight-bold">{{ $t('message.product.qty') }}</v-col>
        <v-col cols="2" class="pa-0 pl-3 text-right font-weight-bold">{{ $t('message.order.avail') }}</v-col>
        <v-col cols="2" class="pa-0 pl-3 text-right font-weight-bold">{{ $t('message.order.ordered') }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-0">
          <v-divider class="pa-0"></v-divider>
        </v-col>
      </v-row>
      <!-- Load list of products -->
      <v-row class="pb-3" v-for="(product, index) in listOfProducts.pickedproducts" :key="index" @click="scanProductByClick(product.productnumber)">
        <v-col cols="3" class="pa-0 pl-3 text-truncate" :title="product.number" :class="{ 'pb-1': (index === listOfProducts.pickedproducts.length - 1)}">
          {{ product.number }}
        </v-col>
        <v-col cols="3" class="pa-0 text-center text-truncate" :title="product.locationname">{{ product.locationname }}</v-col>
        <v-col cols="2" class="pa-0 text-right text-truncate" :title="product.qty">{{ product.qty | absoluteNumber }}</v-col>
        <v-col cols="2" class="pa-0 pl-3 text-right text-truncate" :title="product.productavaiable">
          <template>{{ product.productavaiable | absoluteNumber }}</template><!--  v-if="product.producttype === 'product'" -->
          <!-- <template v-else>0</template> -->
        </v-col>
        <v-col cols="2" class="pa-0 pl-3 text-right text-truncate">
          {{ product.orderedqty | absoluteNumber }}
          <span class="caption" :title="product.unitname">{{ product.unitname }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { hostAppApi } from '@/plugins/axios_settings'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: false,
      orderId: 0,
      listOfProducts: [],
      orderModel: {},
      scanCode: '',
      windowSize: {
        x: 0,
        y: 0
      },
      productFound: false,
      productInfo: {},
      pickQty: '',
      listOfLocations: [],
      selectedLocation: '',
      selectBatch: '',
      saveLoading: false,
      loadingBatch: false,
      isAllPicked: false,
      moreLocationsFound: false,
      batchLocations: [],
      getbatch: [],
      productLoading: false,
      loadingLocation: false,
      projectId: parseInt(this.$route.params.project_id),
      listOfWarehouses: [],
      selectedWarehouse: 0,
      locationsSet: false,
      /* Scanner field */
      scannerPayload: {
        placeholder: 'message.order.scanProduct',
        change: this.getRespectiveProductOrder,
        attrRef: 'scannerFieldFocus',
        attrId: 'scannerField'
      },
      unPlannedProduct: false
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  components: {
    'barcode-scanner': () => import('@/components/BarQrCodeScanner')
  },
  mounted () {
    this.getProjectAndMaterils()
    this.loading = true
    this.orderId = this.$route.params.order_id
    /*  this.getMaterialProducts()
    this.getOrderInfo() */
    this.onResizeHandler()
    this.focusField('scanFocus')
    this.scanCode = this.$route.params.product_code
  },
  watch: {
    pickQty (val) {
      const pickedQty = +this.$formatter.replaceCommaWithDot(this.pickQty || 0)
      const locations = this.listOfLocations
      if (this.selectedLocation) {
        const getOnStockValue = locations.find(x => x.locationname === this.selectedLocation)
        if (getOnStockValue) {
          if (this.productInfo.isbatch && getOnStockValue.batches) {
            const getBatchQty = getOnStockValue.batches
            const getObj = getBatchQty.find(x => x.batchnumberpwa === this.selectBatch)
            if (getObj) {
              if (pickedQty > getObj.onstock) {
                this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.order.qtyExceeds' })
                setTimeout(() => {
                  this.pickQty = getObj.onstock
                })
              }
            } else {
              const onstock = getOnStockValue.availableqty
              if (pickedQty > onstock) {
                this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.order.qtyExceeds' })
                setTimeout(() => {
                  this.pickQty = onstock
                })
              }
            }
          } else {
            const onstock = getOnStockValue.availableqty
            if (pickedQty > onstock) {
              this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.order.qtyExceeds' })
              setTimeout(() => {
                this.pickQty = onstock
              })
            }
          }
        } else {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.product.noLocFound' })
          setTimeout(() => {
            this.pickQty = ''
            this.selectedLocation = ''
            this.focusField('moreLocation')
          })
        }
      }
      if (this.unPlannedProduct) this.productInfo.orderedqty = pickedQty
      const orderedQty = this.productInfo.orderedqty
      if (pickedQty > orderedQty) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.order.qtyExceeds' })
        setTimeout(() => {
          this.pickQty = orderedQty
        })
      }
    }
  },
  methods: {
    getProjectAndMaterils () {
      this.loading = true
      hostAppApi.get(`${this.getHostRefApi}projects/get_materials/${this.projectId}?user_id=${this.userId}&search_term=${this.$route.params.product_code}`)
        .then((response) => {
          if (response.data) {
            const { order, topickproducts, pickedproducts, warehouses } = response.data
            this.orderModel = order
            this.listOfWarehouses = warehouses
            if (topickproducts && topickproducts.length > 0) this.isAllPicked = false
            else this.isAllPicked = true
            this.listOfProducts = { topickproducts, pickedproducts }
            this.selectedLocation = ''
            this.selectBatch = ''
            this.pickQty = ''
            this.productInfo = {}
            this.scanCode = ''
            this.productFound = false
            this.focusField('scanFocus')
          }
        })
        // .finally(() => { this.loading = false })
        .finally(() => {
          this.loading = false
          this.scanCode = this.$route.params.product_code
          this.getRespectiveProductOrder()
        })
    },
    getLocationsByWarehouse () {
      hostAppApi.get(`${this.getHostRefApi}get_location_by_warehouse/${this.selectedWarehouse}?user_id=${this.userId}&stockable_location=1&product_id=${this.productInfo.product_id || 0}`)
        .then((response) => {
          if (response.data) {
            this.listOfLocations = response.data.locations || []
            const getStockLocations = this.listOfLocations.filter(x => x.availableqty > 0)
            if (this.productInfo.producttype === 'work') {
              setTimeout(() => {
                this.focusField('qtyRef')
              }, 50)
            }
            if (this.productInfo.isbatch) {
              setTimeout(() => {
                this.focusField('batchRef')
              }, 50)
            } else {
              setTimeout(() => {
                this.focusField('moreLocation')
              }, 50)
            }
            if (getStockLocations.length === 1) {
              setTimeout(() => {
                this.selectedLocation = getStockLocations[0].locationname
              }, 50)
            } else if (getStockLocations.length > 1) {
              setTimeout(() => {
                this.focusField('moreLocation')
              }, 100)
            }
            this.locationsSet = true
          } else this.locationsSet = false
        })
    },
    getOrderInfo () {
      const model = {
        expand: [{
          select: ['name'],
          model: 'Customer',
          on_field: 'id',
          on_parent_field: 'customerid'
        }],
        filter: `id eq '${this.orderId}'`
      }
      this.$api.execute('post', 'orders/query', model)
        .then((response) => {
          if (response.data) {
            this.orderModel = response.data[0]
          }
        })
    },
    getMaterialProducts () {
      this.$api.execute('get', `/ordermaterials/get_materials/${this.orderId}`)
        .then((response) => {
          if (response.data) {
            this.listOfProducts = response.data
            if (this.listOfProducts && this.listOfProducts.topickproducts && this.listOfProducts.topickproducts.length > 0) this.isAllPicked = false
            else this.isAllPicked = true
            this.selectedLocation = ''
            this.selectBatch = ''
            this.pickQty = ''
            this.productInfo = {}
            this.scanCode = ''
            this.productFound = false
            this.focusField('scanFocus')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getRespectiveProductOrder () {
      this.selectedLocation = ''
      if (this.scanCode) {
        this.productLoading = true
        const list = this.$formatter.cloneVariable(this.listOfProducts.topickproducts)
        if (list) {
          // to clear values
          this.selectBatch = ''
          this.pickQty = ''
          this.moreLocationsFound = false
          this.batchLocations = []
          let getProduct = list.find(x => x.barcode === this.scanCode || x.product_number === this.scanCode || (x.batches && Array.isArray(x.batches) && x.batches.find(y => y.value.toString() === this.scanCode)) || (x.qrcodes && Array.isArray(x.qrcodes) && x.qrcodes.find(z => z.qr_code.toString() === this.scanCode)))
          this.unPlannedProduct = false
          if (!getProduct) {
            const response = await this.getProductByNumber()
            if (response && response.data) {
              getProduct = response.data
              getProduct.orderid = this.orderModel.id
              this.unPlannedProduct = true
            }
          }
          if (getProduct) {
            const filteredQrCodeBatch = getProduct.qr_code_batch.filter(x => x !== '')
            // this.selectBatch = filteredQrCodeBatch.length !== 0 ? filteredQrCodeBatch[0] : ''
            if (filteredQrCodeBatch.length !== 0) {
              this.selectBatch = filteredQrCodeBatch[0]
            }
            this.productInfo = getProduct
            this.productFound = true
            this.listOfLocations = getProduct.locations
            const getStockLocations = getProduct.locations.filter(x => x.availableqty > 0)
            this.getBatch()
            var setBatchValue = getProduct.batches.find(y => y.value.toString() === this.scanCode)
            if (setBatchValue && setBatchValue.value) {
              this.selectBatch = setBatchValue.value
              this.checkTheBatchnumber()
            }
            var setBatchValueInQRCode = getProduct.qrcodes.find(x => x.qr_code === this.scanCode)
            if (setBatchValueInQRCode && setBatchValueInQRCode.batch_number && !getProduct.qr_code_batch.length) {
              this.selectBatch = setBatchValueInQRCode.batch_number
              this.checkTheBatchnumber()
            }
            if (this.productInfo.producttype === 'work') {
              setTimeout(() => {
                this.focusField('qtyRef')
              }, 50)
            }
            if (getProduct.isbatch) {
              setTimeout(() => {
                this.focusField('batchRef')
              }, 50)
            } else {
              setTimeout(() => {
                this.focusField('moreLocation')
              }, 50)
            }
            if (getStockLocations.length === 1) {
              setTimeout(() => {
                this.selectedLocation = getStockLocations[0].locationname
              }, 50)
            } else if (getStockLocations.length > 1) {
              setTimeout(() => {
                this.focusField('moreLocation')
              }, 100)
            }
            if (this.selectBatch !== '') {
              this.checkTheBatchnumber()
            }
          } else {
            this.productInfo = {}
            this.productFound = false
            this.listOfLocations = []
            this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: 'message.product.noProductFound' })
            this.focusField('scanFocus')
            this.scanCode = ''
          }
        }
        this.productLoading = false
      }
    },
    getProductByNumber () {
      return hostAppApi.get(`${this.getHostRefApi}get_product_info?search_term=${this.scanCode}&user_id=${this.userId}`)
    },
    onResizeHandler () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight - 40 }
    },
    decreaseQty () {
      if (this.pickQty > 0) this.pickQty--
    },
    increaseQty () {
      if (this.pickQty < 0) this.pickQty = ''
      this.pickQty++
    },
    checkTheBatchnumber () {
      this.pickQty = ''
      this.selectedLocation = ''
      this.moreLocationsFound = false
      this.batchLocations = []
      const products = this.$formatter.cloneVariable(this.productInfo)
      let locations = []
      if (products && products.batches) {
        this.loadingBatch = true
        const getStock = products.batches
        // for (let i = 0; i < getStock.length; i++) {
        //   const getBatches = getStock[i].batches
        //   if (getBatches) {
        //     const getBatchWithStock = getBatches.find(x => x.batchnumberpwa === this.selectBatch)
        //     if (getBatchWithStock) {
        //       getBatchWithStock.locationid = getStock[i].locationid
        //       getBatchWithStock.locationname = getStock[i].locationname
        //       batches.push(getBatchWithStock)
        //     }
        //   }
        // }
        let getOnStock = 0
        if (getStock) {
          const getBatchWithStock = getStock.find(x => x.batchnumberpwa === this.selectBatch)
          locations = getBatchWithStock ? getBatchWithStock.locations : []
          this.listOfLocations = locations
          getOnStock = getBatchWithStock ? getBatchWithStock.onstock : ''
        }
        if (locations && locations.length <= 0 && !this.selectBatch) {
          this.selectBatch = ''
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.order.notValidBatch') })
          setTimeout(() => {
            this.focusField('batchRef')
          })
        }
        if (locations && locations.length > 1) {
          this.batchLocations = locations
          this.moreLocationsFound = true
          this.focusField('moreLocation')
        } else {
          this.batchLocations = locations
          this.moreLocationsFound = true
          if (locations) {
            if (getOnStock > this.productInfo.orderedqty) this.pickQty = this.productInfo.orderedqty
            else this.pickQty = getOnStock
            this.selectedLocation = locations[0] ? locations[0].locationname : ''
            this.focusField('qtyRef')
          }
        }
        this.loadingBatch = false
      }
    },
    getBatch () {
      this.getbatch = []
      for (let i = 0; i < this.listOfLocations.length; i++) {
        if (Array.isArray(this.listOfLocations[i].batches)) {
          for (let j = 0; j < this.listOfLocations[i].batches.length; j++) {
            this.getbatch.push(this.listOfLocations[i].batches[j])
          }
        }
      }
    },
    getItemText (item) {
      const qty = item.qty.split('.')[0]
      return `${item.locationname} (${qty})`
    },
    pickQtyOnOrder () {
      const pickedQty = +this.$formatter.replaceCommaWithDot(this.pickQty || 0)
      if (this.$refs.pickForm.validate() && pickedQty > 0) {
        this.saveLoading = true
        const locations = this.listOfLocations
        const getOnStock = locations.find(x => x.locationname === this.selectedLocation)
        let warehouseid = 0
        let locationid = 0
        let locationname = ''
        if (getOnStock) {
          locationid = getOnStock.locationid
          warehouseid = getOnStock.warehouseid
          locationname = getOnStock.locationname
        }
        const productObj = this.$formatter.cloneVariable(this.productInfo)
        productObj.qty = this.$formatter.replaceCommaWithDot(this.productInfo.orderedqty)
        productObj.pickedqty = pickedQty
        productObj.invoiceqty = pickedQty
        productObj.batchnumberpwa = this.productInfo && this.productInfo.isbatch ? this.selectBatch : null
        productObj.locationid = locationid
        productObj.warehouseid = warehouseid
        if (this.unPlannedProduct) { // only for unplanned product
          productObj.id = 0
          productObj.locationname = locationname
          const obj = this.listOfWarehouses.find(x => x.id === warehouseid)
          if (obj) productObj.warehousename = obj.shortname
          productObj.order_quantity = productObj.orderedqty
        }
        productObj.dg = this.dgCalculationFormula(productObj.costprice, productObj.price, productObj.discount)
        const model = [productObj]
        hostAppApi.post(`${this.getHostRefApi}products/update_materials/${this.productInfo.orderid}?fromPwa=true&user_id=${this.userId}`, model)
          .then((response) => {
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'message.common.updatedSuccess' })
            this.getProjectAndMaterils()
            this.$router.push(`/projects/${this.projectId}/materials`)
          })
          .finally(() => {
            this.saveLoading = false
          })
      } else {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.common.validationIssue') })
      }
    },
    dgCalculationFormula (price, salesprice, discount) {
      // FORMULA
      // (price - product costprice) / price * 100 => without discount
      // If Discount
      // price * (100 - discount) / 100
      // (result - costprice) / result * 100
      let getDGValue = 0
      getDGValue = price > 0 ? ((salesprice - price) / salesprice) * 100 : 0
      if (discount && discount > 0) {
        const getResult = +salesprice * (100 - discount) / 100
        const dgValue = ((getResult - price) / getResult) * 100
        getDGValue = dgValue
      }
      return (isNaN(getDGValue) ? 0 : (getDGValue || 0))
    },
    checkIfLocationValid () {
      this.loadingLocation = true
      this.pickQty = ''
      const locations = this.listOfLocations
      const getOnStockValue = locations.find(x => x.locationname === this.selectedLocation)
      if (!getOnStockValue) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.product.noLocFound' })
        setTimeout(() => {
          this.pickQty = ''
          this.selectedLocation = ''
          this.focusField('moreLocation')
        })
      } else {
        if (this.productInfo.isbatch) {
          const getBatch = getOnStockValue.batches.find(x => x.batchnumberpwa === this.selectBatch)
          if (getBatch) {
            if (getBatch.onstock > this.productInfo.orderedqty) this.pickQty = this.productInfo.orderedqty
            else this.pickQty = getBatch.onstock
          }
        } else {
          if (getOnStockValue.availableqty > this.productInfo.orderedqty) this.pickQty = this.productInfo.orderedqty
          else this.pickQty = getOnStockValue.availableqty
        }
        this.focusField('qtyRef')
      }
      this.loadingLocation = false
    },
    focusField (value) {
      requestAnimationFrame(() => {
        if (this.$refs[value]) {
          setTimeout(() => {
            this.$refs[value].focus()
          })
        }
      })
    },
    clearPickedQty () {
      this.pickQty = ''
    }
  }
}
</script>
